<template>
  <div
    class="frequentlyAskedQuestion-box px-frequentlyAskedQuestion-box "
    @click="clickItem('box')"
    :class="`edit_${Xindex}_box`"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="sub-title"
      v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
    ></h2>
    <div class="items-box">
      <template v-for="(item,index) in (configs.items.length * 2)">
        <template v-if="index %2 == 0 && configs.items[index]">
          <div
            :key="index"
            class="item"
          >

            <div class="title-box">
              <div
                v-if="configs.items[index]"
                class="t"
              >Q：{{configs.items[index][`${nowL}title`] || configs.items[index].title}}</div>
              <div
                v-if="configs.items[item]"
                class="t"
              >Q：{{configs.items[item][`${nowL}title`] || configs.items[item].title}}</div>
            </div>
            <div class="content-box">
              <div
                v-if="configs.items[index]"
                class="d"
              >A：<span v-html="$util.rex.getHtml(configs.items[index][`${nowL}desc`] || configs.items[index].desc)"></span></div>
              <div
                v-if="configs.items[item]"
                class="d"
              >A：<span v-html="$util.rex.getHtml(configs.items[item][`${nowL}desc`] || configs.items[item].desc)"></span></div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          subTitle: "",
          items: []
        }
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.frequentlyAskedQuestion-box {
  padding: 60px 310px 26px;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .sub-title {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
    text-align: center;
  }
  .items-box {
    margin-top: 40px;
    .item {
      .title-box {
        display: flex;
        .t {
          width: calc(50% - 50px);
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          &:nth-child(2) {
            margin-left: auto;
          }
        }
      }
      .content-box {
        display: flex;
        .d {
          width: calc(50% - 50px);
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 14px;
          color: #000000;
          border-bottom: 1px dashed #999999;
          padding-bottom: 20px;

          &:nth-child(2) {
            margin-left: auto;
          }
        }
      }
      margin-bottom: 34px;
    }
  }
}

@media screen and(max-width:1000px) {
  .px-frequentlyAskedQuestion-box {
    padding: 30px 30px 23px;
    .title {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .items-box {
      .item {
        margin-bottom: 17px !important;
        .t {
          font-size: 14px !important;
          font-family: PingFang SC-Medium, PingFang SC !important;
          font-weight: 500 !important;
          color: #000000 !important;
          width: calc(50% - 10px) !important;
        }
        .d {
          font-size: 12px !important;
          font-family: PingFang SC-Regular, PingFang SC !important;
          font-weight: 400 !important;
          color: #000000 !important;
          width: calc(50% - 10px) !important;
          padding-bottom: 10px !important;
          border-bottom-width: 1px !important;
          margin-top: 8px !important;
        }
      }
    }
  }
}

@media screen and(max-width:500px) {
  .px-frequentlyAskedQuestion-box {
    padding: 15px 18px 0;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
    }
    .items-box {
      .item {
        margin-bottom: 15px;
        .title-box {
          .t {
            width: calc(50% - 5px);
            font-size: 11px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
        .content-box {
          .d {
            width: calc(50% - 5px);
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-top: 4px;
            padding-bottom: 8px;
            border-bottom: 1px dashed #999999;
          }
        }
      }
    }
  }
}
</style>